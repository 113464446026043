.gobierto-block-header {
  display: flex;
  justify-content: space-between;
  font-size: .75rem;
  line-height: 14px;
  width: 100%;
}

.gobierto-block-header--title {
  text-transform: uppercase;
  font-weight: 700;
}

.gobierto-block-header--link {
  font-weight: 400;
  cursor: pointer;
}

.gobierto-block-header--icon {
  transition: transform ease-in .1s;
  pointer-events: none;
  color: var(--color-base);
}

.gobierto-rotate-icon {
  i {
    transform: rotate(270deg);
  }
}
