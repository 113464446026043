.gobierto-filter-checkbox {
  cursor: pointer;

  input[type="checkbox"] + label {
    background: transparent;
    padding: 6px 0;
    display: flex;

    &::before {
      content: "";
      border: 1px solid $separator_dark;
      display: inline-block;
      border-radius: 3px;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      line-height: 16px;
      text-align: center;
      transition: all .2s ease-out;
      flex: 0 0 auto;
    }
  }

  input[type="checkbox"] + label:hover,
  input[type="checkbox"]:checked + label {
    $space: 1em;

    width: calc(100% + #{$space});
    margin-left: - $space * 0.5;
    margin-right: - $space * 0.5;
    padding-left: $space * 0.5;
    padding-right: $space * 0.5;
    box-sizing: border-box;
    background-color: rgba(var(--color-base-string), 0.1);
  }

  input[type="checkbox"]:checked + label::before {
    background: var(--color-base);
    color: $color_main_negative;
    content: "✔";
  }

  &:hover {
    background: transparent;
  }

}

.gobierto-filter-checkbox--input + label {
  background: transparent;
  padding: 6px 0;
  display: flex;

  &::before {
    content: "";
    border: 1px solid $separator_dark;
    display: inline-block;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    line-height: 16px;
    text-align: center;
    transition: all .2s ease-out;
    flex: 0 0 auto;
  }

}

.gobierto-filter-checkbox--label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: flex;
  align-items: center;
  position: relative;
}

.gobierto-filter-checkbox--label-counter {
  margin-left: 0.5em;
  opacity: 0.5;
  font-style: normal;
}

.gobierto-filter-checkbox--label-title-text {
  margin: 0;
  display: inline;
  line-height: 1.3;
}
