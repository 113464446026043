.header_block_inline {
  h2 {
    margin: 0.85em 0 2em;
  }

  .last_update {
    margin-top: 2em;
    margin-bottom: 0.25em;
  }

  .inline_header {
    h2,
    .breadcrumb {
      display: inline-block;
    }

    .breadcrumb {
      padding: 0;
    }
  }

  p {
    line-height: 1.4;
    font-size: 1rem;

    @media (width >= 768px) {
      font-size: 1.125rem;
    }
  }

  .img_header {
    max-width: 400px;
    width: 100%;
    height: auto;
  }
}