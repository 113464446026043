.gobierto-vue-dropdown {
  &--trigger {
    cursor: pointer;
    display: flex;
    align-items: baseline;

    & + * {
      margin-top: 6px;
    }
  }

  .rotate-caret {
    transform: rotate(270deg);
  }
}
