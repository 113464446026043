@use "sass:color";
@import "normalize.css";
@import "purecss/build/pure-nr";
@import "purecss/build/grids-responsive";
@import "@fortawesome/fontawesome-free/css/all.css";
@import "https://fonts.googleapis.com/css?family=Work+Sans:200,400,700,600|Source+Sans+Pro:200,400,700,600";
@import "modules/css-conf";
@import "modules/populities";
@import "modules/mixins";
@import "modules/comp-forms";
@import "modules/comp-headings_blocks";
@import "modules/comp-layout";
@import "modules/comp-site_header";
@import "modules/comp-tables";
@import "modules/comp-header_block_inline";
@import "modules/comp-social_buttons";
@import "modules/defaults";
@import "modules/comp-metric_boxes";
@import "modules/comp-graph-dc";
@import "modules/comp-vue-block-checkbox";
@import "modules/comp-vue-block-header";
@import "modules/comp-vue-dropdown";
@import "modules/comp-vue-pagination";
@import "modules/comp-vue-multiple-lines";
@import "modules/comp-vue-table";
@import "modules/comp-vue-transitions";
@import "modules/comp-graph-vis_bubbles";

:root {
  --green-table-first: rgb(33 150 83 / 10%);
  --green-table-second: rgb(33 150 83 / 20%);
  --grey: #666;
  --border-grey: rgb(102 102 102 / 50%);
  --table-item-cost: rgb(1 120 168 / 20%);
  --border-separator: rgb(151 151 151 / 50%);
  --border-separator-contracts: rgb(0 0 0 / 10%);
  --button-grey-active: #f4f4f4;
  --white: #fff;
  --black: #000;
  --blue-title-header: #0178a8;
  --grey-text: #767168;
  --border-color-grey: #ccc;
  --color-gobierto-extra-5: #158a2c;
  --color-gobierto-extra-6: #94d2cf;
  --color-gobierto-extra-7: #3a78c3;
  --color-gobierto-extra-8: #15dec5;
  --color-gobierto-extra-9: #6a7f2f;
  --color-gobierto-extra-10: #55f17b;
}

.visualizations {
  @include min-screen(768) {
    @include gutters(66px)
  }

  &-home-aside--block {
    padding: 1rem 0;
    border-bottom: 1px solid var(--border-separator);

    @include min-screen(768) {
      padding: .5rem 0;
    }
  }

  &-home-aside--gap {
    height: auto;
    position: relative;
    padding-bottom: 0;

    @include min-screen(1024) {
      position: sticky;
      top: 0;
      overflow-y: auto;
      height: 100vh;
    }
  }

  &-home-nav {
    background-color: rgba($color_headers, 0.04);
    margin-bottom: 1em;

    &--reset {
      display: flex;
      align-items: flex-end;
      height: 100%;
      font-size: $f7;
      cursor: pointer;
      justify-content: flex-end;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include min-screen(768) {
        justify-content: flex-start;
        flex-direction: row;
        margin-right: 0;
      }
    }

    &--tab {
      color: var(--color-base);
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      text-transform: uppercase;
      padding: 14px 0;
      border-top: 3px solid transparent;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 100ms ease-out;
      text-align: center;
      margin-left: 20px;
      flex: 1 0 auto;
      position: relative;
      z-index: 2;

      @include min-screen(768) {
        flex: 0 0 auto;
        min-width: 100px;
      }

      > :first-child {
        flex: 0 0 25px;
      }

      &.is-active {
        font-weight: 700;
        border-top-color: var(--color-base);
      }

      &.is-active i:first-of-type,
      &:not(.is-active) i:last-of-type {
        display: inline-block;
      }

      &.is-active i:last-of-type,
      &:not(.is-active) i:first-of-type {
        display: none;
      }
    }
  }

  &-home-main {
    $border-radius: 3px 4px 4px;

    height: 100%;
    min-height: 500px;

    &--link {
      color: var(--color-base);
      font-size: $f6;
      font-weight: 700;
      line-height: 19px;
      margin-bottom: 20px;
      display: block;
    }

    &--property {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > :first-child {
        font-size: $f8;
        font-weight: 700;
        line-height: 14px;
        text-transform: uppercase;
        flex: 1 0 25%;
        padding-right: 3%;
        box-sizing: border-box;
      }

      > :last-child {
        font-size: $f7;
        font-weight: 400;
        line-height: 16px;
        flex: 1 0 50%;
      }

      &:not(:last-child) {
        margin-bottom: .5em;
      }
    }

    &--th {
      font-size: $f8;
      font-weight: 700;
      line-height: 14px;
      text-align: left;
      border-top: 0;
      text-transform: uppercase;
      padding: 0 0 0.5em;
      color: var(--color-base);

      @include min-screen(768) {
        &:first-child > * {
          padding-left: 20px;
        }
      }

      &:last-child > * {
        text-align: right;
        padding-right: 0;

        @include min-screen(768) {
          padding-right: 20px;
        }
      }

      &-text {
        display: block;
      }
    }

    &--td {
      padding: 0;
      font-size: $f6;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      border-top: 0;
      cursor: pointer;

      > * {
        border-top: 1px solid;
        border-top-color: rgba(var(--color-base-string), .1);
        padding: 0.5em 0;
      }

      &:first-child > * {
        font-weight: 700;
        margin-left: 0;
        padding-right: 8px;

        @include min-screen(768) {
          margin-left: 20px;
        }
      }

      &:last-child > * {
        text-align: right;
        margin-right: 0;
        padding-left: 8px;

        @include min-screen(768) {
          margin-right: 20px;
        }
      }

      &:not(:first-child, :last-child) > *::first-letter {
        text-transform: uppercase;
      }

      &-text {
        display: block;
      }

    }

    .largest-width-td {
      width: 45%;
    }

    &--tr:hover > * {
      background-color: rgba(var(--color-base-string), .1);

      > * {
        border-top-color: transparent;
      }

      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &--tr:hover + &--tr > * > * {
      border-top-color: transparent;
    }
  }

  &-home-aside {
    &--download-open-data {
      padding: 2rem 0;

      @include screen(769) {
        padding: .75rem 0;
      }

      .visualizations-home-aside--download-open-data-link {
        color: var(--color-base);
        width: 85%;
        display: inline-block;
        vertical-align: top;
        line-height: 1.3;
        font-size: $f7;

        &:hover {
          color: rgba(var(--color-base-string), .8);
        }
      }

      .fas {
        color: var(--color-base);
        margin: 0 0.6em 0 0;
      }
    }
  }

  .container {
    .block {
      .with_description,
      .breadcrumb {
        display: inline-block;
      }

      .breadcrumb {
        margin-left: .5rem;
      }
    }
  }

  .gobierto_visualizations-search-container {
    padding-bottom: 1em;
    border-bottom: 1px solid var(--border-separator);
  }

  .gobierto_visualizations-search-container-wrapper-input {
    width: 100%;
    position: relative;
  }

  .gobierto_visualizations-search-container-input {
    height: auto;
    padding: .5rem 1rem .5rem 1.75rem;
    width: 100%;

    @include screen(769) {
      margin-bottom: 0;
    }
  }

  .gobierto_visualizations-search-btn-clear,
  .gobierto_visualizations-search-btn-search {
    left: 95%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .gobierto_visualizations-search-btn-search {
    cursor: pointer;
    opacity: .3;
    left: 6%;

    @include screen(769) {
      left: 3%;
    }
  }

  .treemap-nav {
    &-container {
      position: relative;
      display: grid;

      > * {
        grid-area: span 1;

        @include min-screen(768) {
          grid-area: 1 / -1;
        }
      }

      & + .treemap-nav-container {
        margin-top: 4rem;
      }
    }

    &-buttons {
      display: flex;
      place-self: flex-start auto;
      z-index: 1;

      @include min-screen(768) {
        display: block;
        justify-self: flex-end;
      }
    }
  }

  .treemap-breadcrumb {
    color: rgba(var(--color-base-string), .8);
    fill: rgba(var(--color-base-string), .8);
    width: max-content;
    cursor: pointer;
    font-size: $f6;
  }

  .treemap-item {
    &-title {
      margin: 0;
      font-size: $f6;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1;
      color: var(--white);
    }

    &-text {
      margin: .25rem 0;
      font-size: $f6;
      line-height: 1;
    }

    &:hover .is-leaf {
      text-decoration: underline;
    }
  }

  .treemap-tooltip {
    &-children-container {
      padding: .75rem 0;

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color-grey);
      }
    }

    &-children-title {
      font-size: $f7;
      font-weight: bold;
      color: var(--black);
      line-height: 1.1;
      margin: 0;
    }

    &-children-text {
      margin: .25rem 0;
      font-size: $f7;
      line-height: 1;
      color: var(--grey-text);
    }
  }

  .beeswarm-container {
    color: var(--color-base);
  }

  .treemap-tooltip-header,
  .beeswarm-tooltip-header {
    font-size: $f6;
    color: var(--blue-title-header);
    font-weight: bold;
    display: block;
    border-bottom: 1px solid var(--border-color-grey);
  }

  .beeswarm-tooltip-table-element {
    border-top: 1px solid var(--border-color-grey);
    padding-top: .25rem;
  }

  .beeswarm-tooltip-table-element-text {
    display: inline-block;
    font-size: $f8;
    color: var(--grey-text);
  }

  &-contracts-show__title {
    font-size: $f2;
  }

  &-contracts-show__header__group {
    margin-bottom: .75rem;
    display: inline-block;
    letter-spacing: initial;
    padding-right: 2rem;
  }

  &-contracts-show__icon {
    color: var(--color-base);
  }

  &-contracts-show__block {
    display: block;
    width: 100%;
  }

  &-contracts-show__text,
  &-contracts-show__text__header {
    font-size: $f6;
    color: var(--grey);
    font-weight: 400;
    font-family: $font_headings;
  }

  &-contracts-show__text__header {
    font-weight: bold;
    display: block;
    padding-bottom: .25rem;
    padding-right: 2rem;
  }

  &-contracts-show__body {
    padding: 1.5rem 0;
    margin: 1.5rem 0;
    border-top: 1px solid var(--border-separator-contracts);
    border-bottom: 1px solid var(--border-separator-contracts);
    width: 100%;
  }

  &-contracts-show__text__bold {
    font-weight: bold;
    padding-left: .25rem;
  }

  &-contracts-show__body__group {
    &:not(:last-child) {
      padding-bottom: 35px;
    }
  }

  &-contracts-show-table {
    td,
    th {
      padding: .25rem;
      border: 0;
      text-align: left;
      font-weight: 400;
      font-size: $f6;
    }
  }

  &-contracts-show__footer_link {
    padding-top: 1.5rem;
    margin-top: 1.5rem;
    border-top: 1px solid var(--border-color-grey);
  }

}

.gobierto-visualizations {
  .pure-g {
    letter-spacing: 0 !important;
  }

  .metric_boxes {
    .metric_box {
      margin-bottom: 1rem;

      .inner {
        margin-right: 0;
        margin-left: 0;
        min-height: auto;
        padding-bottom: 2rem;
      }
    }
  }

  &-grid-dc-charts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }

  &-table {
    td {
      display: block;
      width: 100%;

      &:not(:first-child) {
        &::before {
          width: 120px;
          font-weight: bold;
          content: attr(data-th) ": ";
          display: inline-block;
        }
      }
    }

    th,
    td {
      text-align: left;
      padding: .5rem;
    }

    @include min-screen(740) {
      td > * {
        padding: .75rem .5rem .75rem 0;
        vertical-align: middle;
        display: inline-block;
      }


      td {
        border-top: 0;
        padding: 0 1rem;
        display: table-cell;
        width: 12%;

        &.gobierto-visualizations-table-header--elements::before {
          display: none;
        }
      }

      td.gobierto-visualizations-table-header--nav {
        width: 28%;
        min-width: 28%;
      }

      .gobierto-visualizations-table-header--elements {
        text-align: right;
      }

    }

    &--subheader {
      border-collapse: collapse;
      border-radius: 4px;
      width: 100%;
      overflow: hidden;

      td > * {
        padding: 0;
        vertical-align: middle;
        display: inline-block;
      }

      @include min-screen(740) {
        td > * {
          padding: .75rem .5rem .75rem 0;
        }
      }

    }
  }

  &-table--header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-wrap: wrap;
  }

  &-tablerow--header {
    border-bottom: 1px dashed rgb(0 0 0 / 15%);
    cursor: pointer;

    &:hover {
      background-color: rgba(var(--color-base-string), .1);
    }

    .gobierto-visualizations-table-header--nav-text {
      color: var(--color-base);
      text-decoration: underline;
    }

    &.act-has-children {
      background-color: rgba(var(--color-base-string), .1);
      flex-wrap: wrap;

      &:hover {
        background-color: rgba(var(--color-base-string), .2);
      }

      .gobierto-visualizations-table-header--elements {
        font-weight: bold;
      }

      .gobierto-visualizations-table-header--nav-text {
        font-weight: bold;
        display: inline-block;
        font-size: $f6;
      }

      .gobierto-visualizations-table-header--nav-has-children,
      .gobierto-visualizations-table-header--nav {
        padding-right: 0;
      }

      .gobierto-visualizations-table--secondlevel {
        width: 100%;
        background-color: var(--white);

        .gobierto-visualizations-tablerow--header {
          &:last-child {
            border-bottom: 0;
          }
        }

        .gobierto-visualizations-table-header--nav {
          display: inline-block;

          .gobierto-visualizations-table-header--nav-text {
            font-weight: normal;
            padding-left: 1.5rem;
          }
        }
      }
    }
  }

  &-table-header--nav {
    width: 100%;
    min-width: 100%;
    color: var(--color-base);

    @include min-screen(740) {
      width: 28%;
      min-width: 28%;
    }

    p {
      color: var(--color-base);
    }
  }

  &-table--header--thead {
    align-items: flex-end;

    .gobierto-visualizations-table-header--elements {
      padding: .25rem;
      position: relative;
      display: none;

      @include min-screen(740) {
        display: inline-block;
        padding: 2rem 1.5rem .75rem .25rem;
      }
    }

    .gobierto-visualizations-table-color-direct {
      border-top-left-radius: 4px;
      background-color: rgba(var(--color-base-string), .08);
    }

    .gobierto-visualizations-table-color-indirect {
      background-color: rgba(var(--color-base-string), .12);
    }

    .gobierto-visualizations-table-color-total {
      background-color: rgba(var(--color-base-string), .2);
    }

    .gobierto-visualizations-table-color-inhabitant {
      background-color: rgba(var(--color-base-string), .4);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      padding-top: 0;

      @include min-screen(740) {
        padding-top: 3rem;
      }
    }

    .gobierto-visualizations-table-color-income {
      background-color: rgb(33 150 83 / 20%);
    }

    .gobierto-visualizations-table-color-coverage {
      border-top-right-radius: 4px;
      background-color: rgb(33 150 83 / 40%);
    }
  }

  &-table--secondlevel-nested {
    .gobierto-visualizations-table-header--nav {
      padding-left: 2.75rem;
    }
  }

  &-table-header--elements {
    width: 16.3%;
    box-sizing: border-box;
    text-align: right;

    @include min-screen(740) {
      width: 12%;
    }

    i {
      display: inline-block;
      vertical-align: middle;
    }

    span {
      font-size: 12px;

      @include min-screen(740) {
        font-size: $f6;
      }
    }
  }

  &-table-color-direct {
    background-color: rgba(var(--color-base-string), 0.04);
  }

  &-table-color-indirect {
    background-color: rgba(var(--color-base-string), 0.06);
  }

  &-table-color-total {
    background-color: rgba(var(--color-base-string), .1);
  }

  &-table-color-inhabitant {
    background-color: rgba(var(--color-base-string), 0.2);
  }

  &-table-color-income {
    background-color: var(--green-table-first);
  }

  &-table-color-coverage {
    background-color: var(--green-table-second);
  }

  &-header--elements-text {
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    color: var(--grey);
    max-width: calc(100% - 19px);
  }

  &-table-first-level {
    .gobierto-visualizations-table-header--link,
    .gobierto-visualizations-table-header--link-top {
      font-size: $f5;
    }
  }

  &-table-header--link,
  &-table-header--link-top {
    font-size: $f6;
    color: var(--color-base);
    text-decoration: underline;
  }

  &-table-header--link-top {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 1rem;
  }

  &-tablesecondlevel--header {
    background-color: var(--color-base);
    border-radius: 4px;
    box-shadow: 0 0 6px rgb(0 0 0 / 25%);
    padding: .5rem 0;
    border-bottom: 0;
    cursor: initial;

    &:hover {
      background-color: var(--color-base);
    }

    .gobierto-visualizations-table-header--nav,
    .gobierto-visualizations-table-header--nav-text,
    .gobierto-visualizations-table-header--elements {
      color: var(--white);
      font-size: $f5;
      text-decoration: none;
      font-weight: bold;
      background-color: transparent;
    }

    &.act-has-children {
      background-color: var(--color-base);
      border-radius: 4px;
      box-shadow: 0 0 6px rgb(0 0 0 / 25%);
      padding: .5rem 0;
      border-bottom: 0;
      cursor: initial;

      &:hover {
        background-color: var(--color-base);
      }

      .gobierto-visualizations-table-header--nav,
      .gobierto-visualizations-table-header--nav-text,
      .gobierto-visualizations-table-header--elements {
        color: var(--white);
        font-size: $f5;
        text-decoration: none;
        font-weight: bold;
        background-color: transparent;
      }

    }
  }

  &-table-header--link-container {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 1rem;

    i {
      display: inline-block;
      vertical-align: middle;
    }

    .gobierto-visualizations-table-header--link-top {
      padding-bottom: 0;
    }
  }

  &-table-item {
    background: var(--white);
    box-shadow: 0 0 6px rgb(0 0 0 / 25%);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    width: 96.5%;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;

    @include min-screen(740) {
      flex-wrap: nowrap;
      padding: 3rem;
    }
  }

  &-table-item-left {
    width: 100%;

    @include min-screen(740) {
      width: 60%;
    }
  }

  &-table-item-right {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    @include min-screen(740) {
      width: 45%;
    }
  }

  &-table-item-left-container {
    margin-bottom: 1.5rem;
  }

  &-table-item-title,
  &-table-item-text {
    font-size: $f6;
    font-weight: normal;
    color: var(--grey);
    display: block;
    max-width: 30rem;
  }

  &-table-item-title {
    font-weight: bold;
    text-transform: uppercase;
  }

  &-table-item-right-container {
    width: 100%;
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    &-income,
    &-coverage {
      .gobierto-visualizations-table-item-right-table {
        border-color: var(--green-table-second);
      }
    }
  }

  &-table-item-right-section {
    width: 22%;
    display: inline-block;
    padding-right: .5rem;
  }

  &-table-item-right-section-title {
    font-size: $f6;
    color: var(--border-grey);
    text-align: right;
    display: inline-block;
    width: 100%;
    text-transform: uppercase;
  }

  &-table-item-right-table {
    border-left: 7px solid var(--table-item-cost);
    padding-left: 1rem;
    width: 100%;

    @include min-screen(740) {
      width: 70%;
    }
  }

  &-table-item-right-table-element {
    padding: .25rem 0;
    width: 100%;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  &-table-item-right-table-container {
    border-bottom: 1px dashed rgb(0 0 0 / 15%);
    width: 89%;
    margin-right: .75rem;
    display: inline-block;
  }

  &-table-item-right-table-element-bold {
    font-weight: bold;
  }

  &-table-item-right-table-text,
  &-table-item-right-table-amount {
    font-size: $f6;
    color: var(--grey);
    width: 49%;
    display: inline-block;
  }

  &-table-item-right-table-amount {
    text-align: right;
  }

  &-title {
    color: var(--color-base);
    margin-bottom: 1rem !important;
    display: inline-block;

    &.gobierto-visualizations-title-select {
      width: auto !important;
      margin-right: 1rem;
      margin-bottom: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-description {
    margin-top: 0;
  }

  &-description-color-base {
    color: var(--color-base);
  }

  .vis-costs {
    position: relative;
    background-color: #f0f0f0;
  }

  &-header--tooltip,
  &-item--tooltip {
    position: absolute;
    background: white;
    z-index: 4;
    min-width: 200px;
    max-width: 250px;
    padding: .5rem;
    border: 1px solid #979797;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;
    text-align: left;
    bottom: 80px;
    left: -10px;

    &::before,
    &::after {
      border-radius: 2px;
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent color.adjust(#979797, $lightness: -20%);
      content: '';
      transform: rotate(180deg);
    }

    &::after {
      margin-left: -6px;
      border-width: 6px;
      border-color: transparent transparent white;
    }
  }

  &-item--tooltip {
    bottom: 115%;
    left: 66%;
    font-family: $font_headings;
    color: var(--grey);
  }

  &-select {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.15em;
    font-weight: 200;
    color: var(--grey);
    padding: 0.3em 0.75em;
    width: 100%;
    max-width: 100px;
    box-sizing: border-box;
    margin: 0;
    border-radius: 4px;
    appearance: none;
    background-color: rgb(1 120 168 / 10%);
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    height: auto;
    margin-left: .75rem;
  }

  &-select-option {
    height: auto;
    padding: 0;
  }

  &-container-title {
    margin: 2rem 0 1rem;
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    .gobierto-visualizations-title-select {
      margin: 0 !important;
    }
  }

  .range-slider-costs {
    width: max-content;
    margin: 1rem auto;
  }

  .range-slider-costs--values {
    display: inline-block;
    cursor: pointer;

    &:last-child {
      .range-slider-costs--values-circle {
        left: calc(100% - 22px);
      }

      .range-slider-costs--values-text {
        left: calc(100% - 32px);
      }
    }

    &:not(:first-child, :last-child) {
      .range-slider-costs--values-circle {
        left: calc(100% - 32px);
      }
    }

    &.active-slider {
      .range-slider-costs--values-circle {
        background-color: var(--color-base);
      }

      .range-slider-costs--values-text {
        font-weight: bold;
      }
    }
  }

  .range-slider-costs--container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    &::after {
      content: '';
      height: 10px;
      width: 100%;
      background-color: #ddd;
      margin: auto;
      position: absolute;
      bottom: calc(100% - 20px);
      left: 5px;
      z-index: 1;
    }
  }

  .range-slider-costs--values-circle {
    width: 24px;
    height: 24px;
    display: block;
    background-color: #ddd;
    border: 2px solid var(--white);
    border-radius: 100%;
    position: relative;
    z-index: 2;
  }

  .range-slider-costs--values-text {
    font-size: 1.25rem;
    font-weight: 300;
    position: relative;
  }

  .header_block_inline p.visualizations-text-info {
    font-size: $f7;
  }

  &-table-debts {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
  }

  &-table-debts-tr,
  &-table-debts-td {
    font-family: $font_headings;
    color: #666;
    font-size: $f7;
    border: 0;
  }

  &-table-debts-tr:not(:last-child) {
    border-bottom: 1px dotted rgb(0 0 0 / 15%);
  }

  &-table-debts-tr:last-child td {
    font-weight: bold;
  }

  &-table-debts-td {
    &:last-child {
      background-color: rgba(var(--color-base-string), .2);
      text-align: right;
      padding: .5rem;
    }
  }

  &.gobierto-visualizations-debts .treemap-item {
    cursor: initial;

    .treemap-item div:last-child {
      display: none;
    }
  }

  &.gobierto-visualizations-debts {
    .gobierto-visualizations-title {
      margin-top: 6rem;
    }

    .header_block_inline h2 {
      margin-bottom: $f6;
    }

    .bar-chart-stacked-debts {
      .extra-legend-text {
        font-size: 14px;
        font-weight: bold;
        text-transform: capitalize;
        fill: #828282;
      }

      .extra-legend-value {
        font-size: 14px;
        fill: #828282;
      }
    }

    .bar-chart-small-debts {
      text.label {
        font-size: $f7;
      }

      .axis-y .tick line {
        display: none;
      }

      .column .title tspan,
      .column .wrap-text tspan {
        font-weight: 600;
        font-family: $font_headings;
        font-size: $f7;
      }

      .column .title {
        transform:translate(0, 13px);
      }

      .column .wrap-text {
        transform:translate(0, -2px);
      }

    }
  }

}
