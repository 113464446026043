@use "sass:color";

.vis-bubbles {
  position: relative;
  margin-bottom: 0;

  svg {
    overflow: visible;
  }

  .tooltip {
    display: none;
    position: absolute;
    background: white;
    padding: 10px;
    z-index: 4;
    min-width: 200px;
    max-width: 250px;
    border: 1px solid #979797;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    border-radius: 4px;

    .line-name {
      line-height: 1.3;
      margin-bottom: 0.15rem;
    }

    .line-pct {
      font-size: 0.775rem;
      margin-top: 0.25rem;
      text-transform: uppercase;

      i {
        margin-left: 0.25rem;
        display: inline;
      }
    }

    @media (width <= 590px) {
      position: fixed;
      inset: auto -1px -1px;
      width: calc(96%);
      width: auto !important;
      max-width: none;
    }
  }

  @media (width >= 740px) {
    .tooltip::before {
      border-radius: 2px;
      position: absolute;
      top: -14px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent color.adjust(#979797, $lightness: -20%);
      content: '';
    }

    .tooltip::after {
      border-radius: 2px;
      position: absolute;
      top: -12px;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent white;
      content: '';
    }
  }

  .bubble-g text {
    pointer-events: none;
    font-size: 0.8rem;
  }

  circle {
    cursor: pointer;
    stroke-width: 0.1px;
    stroke: #979797;
  }

  circle:hover {
    stroke: $color_headers;
    stroke-width: 1px;
  }
}

.bubble_legend_wrapper {
  z-index: 1;

  .bubble_legend {
    text {
      fill: var(--color-base);
      font-weight: 600;
      text-shadow: 1px 1px 0 rgb(255 255 255 / 70%), -1px -1px 0 rgb(255 255 255 / 70%), -1px 1px 0 rgb(255 255 255 / 70%), 1px -1px 0 rgb(255 255 255 / 70%);
    }

    .legend-label {
      fill-opacity: 0.65;
      font-size: 0.8em;
      fill: $text_soft;
      text-transform: uppercase;
      font-weight: 400;
    }
  }
}
