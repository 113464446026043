@use "sass:color";

/*
 * CSS Conf
 *
 */
$font_headings: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'sans-serif';
$font_text: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'sans-serif';
$font_serif: 'Georgia, serif';


/*
 * Colors
 *
 */
$color_text: #767168;
$color_main: #00909e;
$color_main_soft: #cce8eb;
$color_proposal_bg: #fffad8;
$color_proposal: #b59200;
$color_dark: #005d67;
$color_dark_sep: color.adjust($color_dark, $alpha: -0.9);
$color_secondary: #f0f0f0;
$color_main_negative: #fff;
$color_separator: #d6d6d6;
$color_neutral_soft: #f5f4f4;
$color_highlight: #f1f4be;
$color_meta_text: #a5a5a5;
$text_soft: $color_meta_text;
$color_facebook: #3a589a;
$color_twitter: #00abef;
$color_link: #276d97;
$color_headers: #676767;
$separator: #f0f0f0;
$separator_dark: rgba(#979797, 0.5);

/* logo colors */
$color_logo_yellow: #f8b206;
$color_logo_pink: #f39b95;
$color_logo_blue: #388f9c;
$test_color: color.adjust($color_logo_yellow, $alpha: -0.8);
$color_warn: #981f2e;
$color_success: #2ca25f;

/* admin semaphore colors */
$color_sem_red: #981f2e;
$color_sem_yellow: #ecad4c;
$color_sem_green: #85a81c;

.color_bg_main {
  background: $color_main;
}

/*
 * Type scale
 *
 */
$mega: 6rem;
$submega: 5rem;
$f1: 3rem;
$f2: 2.25rem;
$f3: 1.5rem;
$f4: 1.25rem;
$f5: 1.125rem;
$f6: 1rem;
$f7: 0.875rem;
$f8: 0.75rem;


/*
 * CSS Custom Variables
 *
 */
:root {
  --color-base-string: var(--color-main, #{color.red($color_main), color.green($color_main), color.blue($color_main)});
  --color-base: rgb(var(--color-base-string));
  --color-gobierto-turquoise: #008e9c;
  --color-gobierto-blue: #12365b;
  --color-gobierto-red: #ff776d;
  --color-gobierto-yellow: #f8b205;
}
