@import "https://unpkg.com/dc@4/dist/style/dc.css";

.graph-title {
  text-transform: uppercase;
  border-bottom: 2px solid $color_main_soft;
  padding-bottom: 0.5em;

  + p {
    font-size: 0.875em;
    opacity: 0.5;
  }
}

.as-block {
  display: block;
}

.dc-chart svg {
  overflow: visible;
}

.dc-chart g.row rect,
.dc-chart rect.bar {
  fill: var(--color-base);

  &.deselected {
    fill: #ccc;
  }

  &.selected + text tspan {
    font-style: italic;
  }
}

.dc-chart g.row text,
.dc-chart .axis,
.dc-chart .axis text {
  font: inherit;
  font-size: 0.875rem;
  fill: $color_text !important;
  white-space: pre;
}

.dc-chart .axis .axis-title {
  opacity: 0.5;
  text-transform: uppercase;
}

.dc-chart .axis line {
  stroke: $color_text;
}

.dc-chart .axis path,
.dc-chart .axis .tick line:not(.grid-line) {
  display: none;
}
