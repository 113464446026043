/*
 * Forms
 *
 */
input[type="text"],
input[type="email"],
input[type="number"],
input[type="submit"],
input[type="password"],
input[type="color"],
input[type="file"],
.button,
button,
select,
option,
textarea {
  box-sizing: border-box;
  height: 74px;
  padding: 0 1em;
  margin: 0 0.5em 0 0;
  display: inline-block;
  vertical-align: middle;

  @include border-radius(6px);

  border: 1px solid $color_separator;
  background: #fafafa;

  @include screen(740) {
    width: 100%;
    margin: 0 0 1em;
  }
}

button:last-child,
.button:last-child {
  margin-right: 0;
}

input,
textarea {
  // Pseudoclasses does not work if they're grouped
  $placeholder-opacity: 0.25;

  /* Chrome, Firefox, Opera, Safari 10.1+ */
  &::placeholder {
    opacity: $placeholder-opacity;
  }

  /* Internet Explorer 10-11 */
  &:input-placeholder {
    opacity: $placeholder-opacity;
  }

  /* Microsoft Edge */
  &::input-placeholder {
    opacity: $placeholder-opacity;
  }
}

input[type="text"].slim,
input[type="email"].slim {
  height: auto;
  padding: 0.25em;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="submit"]:focus,
input[type="password"]:focus,
input[type="file"]:focus,
.button:focus,
button:focus,
select:focus,
textarea:focus {
  border-color: #51a7e8;
  outline: none;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 7.5%), 0 0 5px rgb(81 167 232 / 50%);
  background: #fff;
}

.form_item.input_text,
.form_item.textarea,
.select_control,
.file_field {
  position: relative;
  margin: 0 0 2em;

  label {
    z-index: 1;
    position: absolute;
    width: calc(100% - 1.75rem);
    top: 0.5rem;
    left: 1rem;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 700;

    .indication {
      float: right;
      font-weight: normal;
      opacity: 0.5;
    }
  }

  .form_item_colorpicker {
    position: relative;

    .minicolors-swatch {
      margin-top: auto;
      bottom: 0;
      margin-bottom: 0.8rem;
      margin-left: 12px;
    }

    .minicolors-input {
      padding-left: 52px;
    }
  }

  input,
  textarea {
    padding: 1.75rem 1rem 0.5rem 0.95rem;
    width: 100%;
    outline: none;
  }

  textarea {
    color: inherit;
    font: inherit;
    box-sizing: border-box;
    height: 300px;
  }

  input[type="password"] {
    height: 67px;
    padding: 1.5rem 1rem 0.25rem;
  }
}

.form_item.plugin_field label {
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 700;
}

.select_control {
  background: #fafafa;

  select {
    padding: 0 15px;
    height: 47px;
  }
}

.select_control.select_compact,
.form_item.input_text.compact {
  font-size: 0.85em;

  // margin: 0 0 0.5em 0;
  margin: 0;

  select,
  input {
    margin-top: 0;
    padding: 2px 10px;
    height: initial;
  }

  input {
    // height: 40px;
    height: 28px;
  }
}

.select_control.selects_inline {
  select {
    width: initial;
    display: inline-block;
    max-width: 25%;
  }
}

.file_field {
  border: 1px solid $color_separator;

  @include border-radius(6px);

  padding: 2.5em 1em 1em;

  input[type="file"] {
    padding: 0;
    border: 0;
    height: initial;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

input[type="submit"],
.button,
button {
  transition: all 0.15s ease-out 0s;
  border: 0;
  height: initial;
  background: var(--color-base);
  color: #fff;
  padding: 1em 2em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
}

// TODO
input[type="submit"].button {
  border: 0 !important;
  height: initial !important;
  padding: 1em 2em !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
}

input[type="submit"]:hover,
.button:hover,
button:hover {
  background: rgba(var(--color-base-string), 0.8);
}

input[type="submit"]:disabled,
.button:disabled,
button:disabled {
  background: var(--color-base);
  opacity: .2;
}

button a,
a.button,
.button {
  color: #fff;
  text-decoration: none;
}

button.bl,
.button.bl {
  width: 100%;
  display: block;
}

a.button:hover {
  text-decoration: none;
}

.button:hover,
button {
  color: #fff;
}

button.soft,
.button.soft {
  background: rgba(var(--color-base-string), 0.65);
}

button.light,
.button.light {
  background: $color_main_negative;
  border: 1px solid $color_separator;
  color: var(--color-base);
}

button.gigantic,
.button.gigantic {
  font-size: 1.3rem;
  height: initial;
  padding: 1.4em 4em 1.2em;
  margin: 1em 0;

  @include border-radius(2em);

  text-decoration: none;
}

button.medium,
.button.medium {
  font-size: 0.85rem;
  padding: .65em 1em 0.5em;

  @include border-radius(4px);

  display: inline-block;
}

button.small,
.button.small {
  font-size: 0.85rem;
  padding: 0.45em 1em 0.3em;
  height: initial;
  line-height: 1.2em;

  // font-weight: 300;
  @include border-radius(4px);

  display: inline-block;
}

button.light:hover,
.button.light:hover {
  background: rgba(var(--color-base-string), 0.2);
}

.button_light {
  background: rgba(var(--color-base-string), 0.2);
  transition: all 0.15s ease-out 0s;
  font-size: 0.75em;
  text-decoration: none;
  padding: 0.5em 1.5em;
  line-height: 1.2em;

  @include border-radius(20px);

  margin: 1em 0;
  display: inline-block;
}

.button_light:hover {
  background: rgba(var(--color-base-string), 0.3);
  text-decoration: none;
}

button.outline,
.button.outline {
  background: #fff;
  border: 1px solid rgba(var(--color-base-string), 0.25);
  color: var(--color-base);
}

button.outline:hover,
.button.outline:hover {
  background: rgba(var(--color-base-string), 0.06);
  text-decoration: none;
}

input[type="checkbox"],
input[type="radio"] {
  display: none;
}

.slickgrid-container {
  input[type="checkbox"] {
    display: inline;
  }

  .slick-viewport {
    overflow: visible !important;
  }

  .slick-row.even,
  .slick-row.odd {
    .slick-cell {
      background: white;

      &.active > span {
        margin-left: -5px;
        margin-top: -3px;

        .select2-selection {
          border-radius: 0;
        }
      }

      &.disabled {
        background: #f7f7f6;
        border-color: #d6d7d6 !important;
        border-style: solid;

        &.active {
          border-left-width: 0;
          border-top-width: 0;
          padding-left: 4px;
          padding-top: 4px;
        }
      }
    }
  }
}

.slickgrid-container + [add-dashboard-btn] {
  position: absolute;
  left: 100%;
  background-color: $color_main;
  color: $color_main_negative;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  text-decoration: none;
  height: 25px; // slickgrid defualt rowheight
  padding: 0 0.5em;
  display: flex;
  align-items: center;
  font-size: $f8;
  opacity: 0;
  pointer-events: none;
  z-index: -1;

  &.is-active {
    z-index: 1;
    opacity: 1;
    pointer-events: all;
  }

  > i {
    opacity: 1;
    margin: 0;
  }

  > * + * {
    margin-left: 0.25em;
  }
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  cursor: pointer;
  display: block;
  padding: 14px;
  transition: all 0.15s ease-out 0s;
}

.inline_filter {
  display: inline-block;
  width: 49%;
  margin-bottom: 0.5em;

  span {
    display: block;

    @media (width >= 768px) {
      display: inline-block;
      padding-right: 0.5em;
    }
  }

  .button-group {
    width: 100%;

    @media (width >= 768px) {
      width: auto;
    }
  }

  @media (width >= 768px) {
    width: auto;
    padding-right: 1em;
  }
}

.button-group {
  // letter-spacing: -.31em;

  .button-grouped {
    background: white;
    color: $color_headers;
    font-weight: 400;
    border: 1px solid #cecece;
    padding: 0 0.5em;
    margin: 0;
    border-radius: 0;
    font-size: 14px;

    &:disabled {
      opacity: .5;
    }

    &:hover {
      @extend .active;
    }

    small {
      line-height: 1;
    }
  }

  .button-grouped + .button-grouped {
    @media (width >= 768px) {
      margin-left: -5px;
    }
  }

  .button-wrapper {
    display: inline-block;

    &:first-child {
      @media (width >= 768px) {
        margin-right: -5px;
      }
    }

    &:last-child {
      @media (width >= 768px) {
        margin-left: -5px;
      }
    }
  }

  .button-compact {
    display: block;
    width: 100%;
    margin-top: -1px;
  }

  // Workaround to avoid ERB files whitespace, when dynamic content
  .button-compact + .button-compact {
    @media (width >= 768px) {
      margin-left: 0;
    }
  }

  .button-grouped:first-child {
    border-radius: 2px 0 0 2px;
    margin-left: 0;
  }

  .button-grouped:last-child {
    border-radius: 0 2px 2px 0;
  }

  .active {
    background: #f4f4f4;
    box-shadow: inset 0 0 3px 0 rgb(0 0 0 / 25%);
  }
}

.inline_button {
  background: rgba(var(--color-base-string), 0.1);
  border-radius: 4px;
  color: var(--color-base);
  font-weight: 600;
  text-decoration: none;
  padding: 0.35em 0.75em;
  transition: 0.3s;

  &:hover,
  &:active,
  &:focus {
    background: rgba(var(--color-base-string), 0.15);
    text-decoration: none;
  }
}

.button.rounded {
  border-radius: 20px;
  font-weight: 600;
}

.button.outline.rounded {
  text-transform: none;
  padding: 0.2em 1em;
  border-radius: 20px;
  font-weight: 600;

  .fas {
    font-size: 85%;
  }
}

.button.outline.rounded.wide {
  padding: 0.1em 1.5em;
}

.button.outline.rounded.checked {
  background: var(--color-base);

  .fas {
    color: white;
  }
}

.button.outline.rounded.fat {
  padding: 0.75em 1.5em !important;
  border-radius: 30px !important;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);

  &:focus,
  &:active,
  &:hover {
    background: var(--color-base);
  }
}

input[type="checkbox"] + label span,
input[type="radio"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  border: 1px solid var(--color-base);
  background: #fff;

  @include border-radius(3px);

  margin: -4px 11px 0 0;
  vertical-align: middle;
  cursor: pointer;
}

.password_protected {
  display: none;
}

input[type="checkbox"] + label:hover,
input[type="radio"] + label:hover,
.admin_new_el a:hover {
  background: rgba(var(--color-base-string), 0.1);
}

input[type="checkbox"]:checked + label,
input[type="radio"]:checked + label {
  background: rgba(var(--color-base-string), 0.2);

  .password_protected {
    margin: 0.5em 0;
    font-size: 0.85em;
    line-height: 1.2em;
    display: block;
    padding: 0 0 0 29px;

    p {
      margin: 0 0 0.5em;
    }

    input {
      padding: 5px;

      @include border-radius(3px);

      height: initial;
      width: 100%;
      margin: 0.25em 0;
    }
  }
}

input[type="checkbox"]:checked + label span,
input[type="radio"]:checked + label span {
  background: var(--color-base);
}

input[type="radio"] + label span {
  @include border-radius(13px);
}

select {
  appearance: none;
  appearance: none;
  appearance: none;
  border: 0;
  cursor: pointer;
}

select::-ms-expand { /* for IE 11 */
  display: none;
}

option,
select {
  font-weight: 400;
  padding: 18px;
  width: 100%;

  /* font-size: .8em;
  border: 1px solid #CCC;
  background: #F1F1F1;
  */
}

.option {
  padding: 0;
  position: relative;

  .inline_help {
    margin-left: 2.6em;
  }
}

.option.separate {
  margin-top: 1em;
  padding-top: 1em;
}

.select_control,
.options_cont {
  box-sizing: border-box;
  border: 1px solid $color_separator;

  @include border-radius(6px);

  select {
    margin: 1rem 0 0;
    padding: 20px 0 13px 13px;
    height: 56px;
    outline: none;
  }

  select::-moz-focus-inner {
    border: 0;
    outline: none;
  }

  option {
    display: block;
    height: initial;
    padding: 7px 13px;
    font-size: 14px;
    outline: none;
  }
}

.options_cont {
  margin: 0 0 1em;

  > label {
    margin: 0.5rem 1rem;
    display: block;
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 700;
  }
}

.form_item.textarea.code {
  background: none;

  textarea {
    font-size: 0.85em;
  }
}

.form_item {
  position: relative;

  .options {
    .option {
      display: block;
      margin: 0 0 1em;
    }

    .option_suboptions {
      padding: 0 0 0 3em;
    }
  }

  .options.options_cont {
    .option {
      margin: 0 1em 1em;
      font-size: 0.85em;
      box-sizing: border-box;
    }
  }

  .options.compact {
    font-size: 0.85em;
    line-height: 1.8;

    .option {
      border: 0;
      margin-bottom: 1em;
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
      padding: 5px 9px 5px 2.6em;
      margin: -9px 0 0 -9px;

      @include border-radius(3px);
    }

    input[type="checkbox"] + label span,
    input[type="radio"] + label span {
      position: absolute;
      top: 1em;
      left: 0;
      height: 14px;
      width: 14px;
    }

    .option_contextual {
      background: rgba(var(--color-base-string), 0.2);
      padding: 0.5em 1em 0.5em 2.5em;
      margin-left: calc(-0.5rem - 1px);
      margin-top: -1px;

      .form_item {
        margin-bottom: 1em;
      }

      label {
        display: block;
      }

      input:not([data-timepicker="true"]) {
        width: 100%;
        height: initial;
        padding: 0.5em;
      }

      textarea {
        width: 100%;
      }
    }
  }

  .fas {
    margin: 0 0.5em;
    opacity: 0.5;
  }

  .fa-question-circle {
    position: absolute;
    top: 0.5rem;
    right: 0;
  }

  &.textarea.has-indication {
    .fa-question-circle {
      top: auto;
    }
  }
}

.widget_save {
  .form_item {
    .options.compact {
      .option {
        height: initial;
      }
    }
  }
}

.disclaimer {
  font-size: 0.75em;
  opacity: 0.5;
}

.slim_search {
  margin-top: 0.8em;

  label {
    font-weight: 200;
    padding: 0 1em 0 0;
  }

  input[type="text"] {
    height: 40px;
    width: 300px;
  }

  @include screen(740) {
    text-align: center;
    margin: 0 0 1em;

    input[type="text"] {
      width: calc(100%);
      margin: 0;
    }
  }
}

.attach_file input[type="file"] {
  padding: 1em;
  height: auto;
}

.admin_new_el {
  a {
    display: block;
  }
}

.admin_new_el.inline {
  display: inline-block;
}

/*
  Progress
 */
.circles_progress {
  margin-top: 0.25em;
  padding-left: 0;

  @include reset_ul_li;

  li {
    a {
      display: inline-block;
      padding: 0.35em;

      span {
        display: block;
        float: left;
        width: 8px;
        height: 8px;
        background: #ccc;
        border-radius: 50%;

        &.active {
          background: var(--color-base);
        }
      }
    }

    a:hover {
      background: $color_secondary;
      border-radius: 50%;
    }
  }
}

/*
 * Autocomplete
 *
 */
.autocomplete-suggestions {
  border: 1px solid #999;
  font-size: 12px;
  background: #fff;
  cursor: default;
  overflow: auto;
  box-shadow: 1px 4px 3px rgb(50 50 50 / 64%);
}

.autocomplete-suggestion {
  padding: 2px 10px;
  white-space: nowrap;
  overflow: hidden;
}

.autocomplete-no-suggestion {
  padding: 2px 10px;
}

.autocomplete-selected {
  background: var(--color-base);
  color: #fff;
  cursor: pointer;
}

.autocomplete-selected h2, .autocomplete-selected a {
  color: #fff;
}

.autocomplete-suggestions strong {
  font-weight: bold;
  color: #000;
}

.autocomplete-group strong {
  font-weight: bold;
  font-size: 14px;
  color: #999;
  background: $separator;
  padding: 2px 10px;
  text-transform: uppercase;
  display: block;
  border-bottom: 1px solid $separator;
}

.ui-autocomplete {
  z-index: 1500;
}

.actions_block {
  border-top: 1px solid $color_separator;
  padding: 2em 0 0;
  margin: 2em 0 0;
}

.follow_item {
  button,
  .button {
    width: 100%;
    margin: 0.5em 0 1em;
  }
}

/* Radio toggle */
.radio_toggle {
  display: flex;

  span {
    margin-right: 0.5em;
    font-weight: 600;
  }

  input {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }

  label {
    width: 100%;
    padding: 0 !important;
    background-color: $color_headers;
    border-radius: 60px;
    transition: background 0.4s;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;

    &:hover {
      background: rgb(var(--color-base-string) 0.5) !important;
    }
  }

  label::before,
  label::after {
    display: block;
    position: absolute;
    content: "";
  }

  label::before {
    inset: 1px;
    background-color: #fff;
    border-radius: 60px;
    transition: background 0.4s;
  }

  label::after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 16px;
    background-color: #ddd;
    border-radius: 52px;
    transition: margin 0.4s, background 0.4s;
  }

  input:checked + label::after {
    margin-left: 45px;
    background-color: var(--color-base);
  }
}

.inline_login_cta {
  a {
    margin: 2em 0;
    background: var(--color-base);
    color: #fff;
    border-radius: 6px;
    text-align: center;
    padding: 2em;
    display: block;
  }
}

.f_flex_col {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > div.form_item.input_text,
  > div.form_item.select_control {
    width: 22%;
  }

}

.f_flex_col_7 {
  > div.form_item.input_text,
  > div.form_item.select_control {
    width: 14%;
  }
}

.i_filters {
  .i_filter {
    display: flex;
    border-bottom: 1px solid $color_separator;
    padding-bottom: .8em;
    margin-bottom: .8em;
    font-size: .85rem;

    label {
      width: 70px;
      font-size: .85em;
      color: $color_meta_text;
      align-self: center;
    }

    .i_f_options_text {
      display: flex;
    }

    .i_f_option {
      margin: 0 1em 0 0;
      padding: 0 1em 0 0;
      position: relative;
      line-height: 1.2;

      a {
        padding: .2em .25em;
        display: inline-block;
        border-radius: 4px;
        text-decoration: none;
        color: $color_meta_text;

        span {
          text-decoration: underline;
          color: var(--color-base);
        }
      }

      a:hover {
        background: $color_main_soft;
      }

    }

    .i_f_option_active {
      padding: .2em 1em .2em .25em;
    }

    .i_f_option:not(:last-child)::after {
      content: "";
      border-left: 1px solid $color_separator;
      position: absolute;
      width: 1px;
      height: 20px;
      top: 0;
      right: 0;
    }
  }

  .i_filter:last-child {
    border-bottom: initial;
  }
}

.i_p_status {
  &.not_published,
  &.in_revision,
  &.approved {
    position: relative;
    padding-left: 18px;
  }

  &.not_published::before,
  &.in_revision::before,
  &.approved::before {
    content: "";
    position: absolute;
    border-radius: 36px;
    width: 12px;
    height: 12px;
    left: 0;
  }

  &.not_published::before {
    background: $color_sem_red;
  }

  &.in_revision::before {
    background: $color_sem_yellow;
  }

  &.approved::before {
    background: $color_sem_green;
  }


}
